export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
  },

  // ADx
  {
    header: 'ADx',
  },
  {
    title: 'Campaigns',
    route: 'campaigns',
    icon: 'ListIcon',
  },
  {
    title: 'Contents',
    route: 'contents',
    icon: 'ListIcon',
  },
  {
    title: 'Screens',
    route: 'setups',
    icon: 'AirplayIcon',
  },
  {
    title: 'Partners',
    route: 'partners',
    icon: 'UsersIcon',
    role: 'Admin',
  },
  {
    title: 'Cities',
    route: 'cities',
    icon: 'MapPinIcon',
    role: 'Admin',
  },
  {
    title: 'Segments',
    route: 'segments',
    icon: 'FilterIcon',
    role: 'Admin',
  },
  {
    title: 'add Locations',
    route: 'addLocations',
    icon: 'MapPinIcon',
  },
]
